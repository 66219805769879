import React, { useRef } from 'react';
import styled from 'styled-components';
import { InputComponents } from './styled';
import { TYPE_INPUT } from './constants';
import { PropsInput } from './types';
import { Tooltip } from '../Tooltip';

const WrapperTooltip = styled.div`
  position: absolute;

  right: 10px;
  bottom: 9px;
`;

export const TextInputWithTooltip = ({
  onChange,
  tooltip,
  widthTooltip,
  type = TYPE_INPUT.text,
  ...props
}: PropsInput & { tooltip?: string | JSX.Element; widthTooltip?: string }) => {
  const ref = useRef<null | HTMLInputElement>(null);

  return (
    <div style={{ position: 'relative' }}>
      <InputComponents
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        {...props}
        style={{ paddingRight: '35px' }}
      />
      <WrapperTooltip>{tooltip && <Tooltip text={tooltip} widthTooltip={widthTooltip} />}</WrapperTooltip>
    </div>
  );
};
