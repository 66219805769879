import React, { useState } from 'react';
import styled from 'styled-components';
import { IconTooltip } from './IconTooltip';
import { Text } from '../Text';
import { TriangleIcon } from './TriangleIcon';
import { adaptive } from '../../screen';

type Props = {
  text: string | JSX.Element;
  widthTooltip?: string;
};

const StyledTooltip = styled(Text)<{ widthTooltip: string }>`
  position: absolute;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  line-height: 14px;

  ${({ widthTooltip }) => {
    return `width: ${widthTooltip};`;
  }}

  top: 35px;
  right: -35px;

  padding: 10px 15px 10px 10px;

  background-color: #e5e5e5;
  border-radius: 7px;
  z-index: 1;

  ${adaptive.maxWidth.mobile} {
    top: 28px;
    right: -12px;

    font-size: 9px;
    line-height: 11px;
  }
`;

const TriangleIconWrapper = styled.div`
  position: absolute;

  top: 19px;
  right: 7px;

  ${adaptive.maxWidth.mobile} {
    top: 12px;
  }
`;

export const Tooltip = ({ text, widthTooltip = '156px' }: Props) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        onMouseOver={() => setIsShow(true)}
        onMouseOut={() => setIsShow(false)}
        style={{ cursor: 'pointer', height: '21px' }}
      >
        <IconTooltip />
      </div>
      {isShow && (
        <>
          <TriangleIconWrapper>
            <TriangleIcon />
          </TriangleIconWrapper>
          <StyledTooltip widthTooltip={widthTooltip}>{text}</StyledTooltip>
        </>
      )}
    </div>
  );
};
