import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Error } from '../Error';

export const ErrorHandler = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const status = parseInt(searchParams.get('status') || '500', 10);

  let errorTitle = '';
  let errorMessage = '';

  if (status === 404) {
    errorTitle = `We could not find any certificates with this unique code ${location.state || ''}`;
    errorMessage = 'Please check the code you entered and try again.';
  } else if (status === 401) {
    errorTitle = 'Unauthorized Access';
    errorMessage = 'Your session has expired. Please refresh the page or contact us.';
  } else {
    errorTitle = 'Something went wrong.';
    errorMessage = 'Try again later or contact support.';
  }

  return <Error errorTitle={errorTitle} errorMessage={errorMessage} />;
};
