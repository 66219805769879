import Cookies from 'js-cookie';

export const getAccessToken = async (forceRefresh = false): Promise<string> => {
  const TOKEN_KEY = 'access_token';
  const AUTH_API_URL = 'https://legitgrails-api.com/1/authorize';
  const CLIENT_ID = 'af2ac997-ab74-4ee9-a90b-754d8c15dedf';
  const CLIENT_SECRET = '11485fdd-501e-4d02-8ded-7d1fbe63d85e';

  if (!forceRefresh) {
    const storedToken = Cookies.get(TOKEN_KEY);
    if (storedToken) {
      return storedToken;
    }
  }

  try {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'client-id': CLIENT_ID,
        'client-secret': CLIENT_SECRET,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to authenticate');
    }

    const data = await response.json();
    const token = data.access_token;

    // Store token in cookies
    Cookies.set(TOKEN_KEY, token, { secure: true, sameSite: 'strict' });

    return token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};

export const getCertificate = async (id: string): Promise<Response> => {
  const makeRequest = async (token: string) => {
    return fetch(`https://ymo8ktxz5m.execute-api.eu-west-2.amazonaws.com/1/certificate?id=${id.toUpperCase()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  try {
    // Try with the existing token
    const token = await getAccessToken();
    const response = await makeRequest(token);

    // If unauthorized, refresh token and retry
    if (response.status === 401) {
      const newToken = await getAccessToken(true);
      return makeRequest(newToken);
    }

    return response;
  } catch (error) {
    console.error('Error fetching certificate:', error);
    throw error;
  }
};
